@import '~@strategies/tokens/layout';

.MapLegend {
    .Panel {
        header {
                .Panel--header__left {
                    display: flex;
                    align-items: center;
                    flex: 0.4 1 max-content;
                }

                .Panel--header__right {
                    display: flex;
                    align-items: center;
                    flex: 0.6 1;
                    padding-right: $padding--smallest;

                    .ReactForm__RadioSlider-option {

                        font-size: font.$size--small !important;
                    }
                }
            
        }

    }

    // &:nth-child(4) {
    //     header {
    //         height: 0;
    //     }
    // }

    &:nth-child(2) {
        // height: 80%;
    }
    display: flex;
    flex-direction: column;
    padding-bottom: $padding--small;


  
    h2 {

        .hicon {
            padding-right: 5px;
            padding-top: 2px;
        }

    }

    // & main {
    //     padding-top: $padding--small ;

    // }

    main {

        display: flex;
        align-items: start;
        // padding-left: $padding--small;
        justify-content: start;

    }

    .panel-selector {
        flex: 1 1;
        vertical-align: middle;

        height: 100%;
        margin: layout.$padding--small 0 layout.$padding--small layout.$padding--small;

        text-transform: uppercase;
       
    }


}