@import '~@strategies/tokens/layout';
@import '~@strategies/tokens/color';
@import '~@strategies/tokens/font';

$blue--light: #EDEBFF;

.HoursPanel {

    // height: 100%;

    
    // width: 70%;
    flex: 1 1;
    align-items: center;

    // height: 100%;
    margin: layout.$padding--smallest;

    border-radius: layout.$border-radius--small;
    .panel-day-selector {
        // margin: 15px 15px 15px 15px;
        padding: 10px 10px 10px 10px;

        .ReactForm__RadioSlider{

            .ReactForm__RadioSlider-options{

                
            .ReactForm__RadioSlider-option {

                font-size: 10px;
                padding: 5px 5px 5px 5px;
                // overflow: hidden;
    // white-space: nowrap;
                
            }
            }
        }
    }
    .infoTextWrapperOff {
        color: lightgray;
        font-size: 14px;
        

        b {
            font-size: 20px;
        }
    }

    .infoTextWrapper {
        font-size: 14px;
        

        b {
            font-size: 20px;
        }
    }
    .hourWrapper {
        box-sizing: border-box;

        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px 5px 10px;
        align-content: center;
        flex-wrap: nowrap;
        border-radius: 5px;
        border: 1px solid #F2F5FA;
        padding-left: $padding--smallest;
        padding-right: $padding--smallest;
        // width: 70%;
        flex: 1 1;
        align-items: center;
        margin: 10px;
        .hoursWrapper {
            flex: 0.86 1;
            display: flex;
            width: 100%;
            background-color: $blue--light;

            padding: 5px;
            padding-top: 6px;
            // background-color: var(--token-f440fbaa-40cc-4295-a0cc-f46234c37aa4, #edebff);
            // overflow: visible;
            border-radius: 10px;

            .hour_holder {
                left: 0;
                margin-right: 30px;
                // margin-left:10px;


                .rc-slider .rc-slider-horizontal {
                    margin-right: 30px;
                }
            }

            .rc-slider {
                right: 0;

            }

            .rc-slider-handle,
            .rc-slider-handle .rc-slider-handle-dragging {
                background-color: black;
                border-color: white;
            }

            .rc-slider-track {
                background-color: white;
            }



        }


        .HourTextWrapper {

            display: flex;

            flex-wrap: nowrap;

            .hour_text {
                width: auto;
                height: auto;
                white-space: pre;
                overflow: visible;
                font-weight: 500;
                font-family: "Inter-Medium", "Inter", sans-serif;
                color: #050038;
                letter-spacing: 0px;
                line-height: 1.2;
                text-align: left;

                padding-right: 15px;

            }
        }
    }


    .dayWrapper {


        display: flex;
        align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding-top: 5px;
    padding-bottom: 5px;


    box-sizing: border-box;

        flex-wrap: nowrap;
        border-radius: 5px;
        border: 1px solid #F2F5FA;
        padding-left: $padding--smallest;
        padding-right: $padding--smallest;
        // width: 70%;
        // flex: 1 1;
        align-items: center;

        
        .days-selector {
            padding: 5px;
            margin: 0 0 0 0.5rem;
            // padding-top: 1px;
            background-color: var(--token-f440fbaa-40cc-4295-a0cc-f46234c37aa4, #edebff);
            // overflow: visible;
            border-radius: 10px;
            flex: 0.8 1;
            
            
       .ReactForm__RadioSlider {
                height: 80%;


                .ReactForm__RadioSlider-input {
                    display: block;
                    border-color: #EDEBFF;
                    vertical-align: middle;
                    overflow: visible;
                    height: 80%;
                    background: #EDEBFF;
                }
            }


            .ReactForm__RadioSlider-option {

                padding: 3px 0;
                font-size: .657rem;
            }

        }
        .dayTextWrapper {
            
            .day_text {

            }

           
        }
    }


}