@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/font';

#pulseMap {
    // border: 4px solid color.$white;
    // border-radius: layout.$border-radius;
    // box-shadow: $box-shadow;
    // height:800px;
    // height:100vh;

    .mapboxgl-canvas {
        position: relative;
        left: 0;
        top: 0;
    }

    .map-container {

        height: 100%;
        overflow: hidden;
        border-radius: layout.$border-radius;
    }

    .mapboxgl-popup-content {

        text-align: left;
        color: color.$white;
        background-color: color.$black;
    }

    .mapbox-logo {
        display: none;
    }

    .mapboxgl-ctrl-logo {
        display: none !important;
    }

    .mapbox-improve-map {
        display: none;
    }

    .mapboxgl-ctrl-compass {
        display: none;
    }

    .mapboxgl-control-container {
        position: absolute;
        top: 75px;
        left: 0;

        .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
            margin-top: 0;
            box-shadow: none;
        }

        .mapboxgl-ctrl-group:first-child {
            border-radius: 4px 4px 0px 0px;

        }

        .mapboxgl-ctrl-group:nth-child(2) {
            border-radius: 0px 0px 0px 0px;

        }

        .mapboxgl-ctrl-group:last-child {
            border-radius: 0px 0px 4px 4px;

        }

        .mapboxgl-ctrl-group button+button {
            border-top: none;
        }

        .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {

            background-image: url(../Map/assets/maximize-2.svg);
        }

        .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
            background-image: url(../Map/assets/minimize-2.svg)
        }

        .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
            background-image: url(../Map/assets/plus.svg)
        }

        .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
            background-image: url(../Map/assets/minus.svg)
        }

        .mapboxgl-ctrl-group .mapboxgl-export-control {
            background-image: url(../Map/assets/printer.svg);
            border-radius: 4px 4px 0px 0px;
           
        }
        table.print-table {
            text-align: left;
            font-size: smaller;
        }
        select {
            border: none;
        }
    }

}