@import '~@strategies/tokens/font';
@import '~@strategies/tokens/color';

.CategoryBreakdownPanel {
    font-size: $size--small ;
    text-align: start;
    display: flex;
    flex-direction: column;
    // padding: 15px;
    width: 100%;

    .subCategoryBreakdownitem{
        align-items: start;
        display: flex;
        justify-content: space-between;

        .item-legend {
            display: flex;
            flex: 0 0 0em;
            border-radius: 50%;
            height: 100%;
            color: black;
        }
       
        > div {
            flex: 0.7 1 5em;
            display: flex;
            padding: 10px 50px 10px 60px;
            box-sizing: border-box;
            height: 35px;
            width: 200px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--token-498f5f00-091f-442f-aed7-469a644be658, #ffffff);
            overflow: visible;
            align-content: start;
            flex-wrap: nowrap;
            gap: 0;
            
            flex-basis: 100px;
            text-align: left;
            > div {
                flex: 0.5 0.5 0.1em;
            }
            .subCount {
                display: flex;
                justify-content: right;
            }

        &:hover {
            color: $blue;
            cursor: pointer;
            
        }
 
        }

    
    }


}
