@import '~@strategies/tokens/font';
@import '~@strategies/tokens/layout';

.CategoriesPanel{
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    font-size: $size--small ;
    // height: 100%;
    max-height: 45vh;
    // overflow: auto;


    // ul {

    //     padding: 15px;
    // }



    .select-deselct-all {
        display: flex;
        justify-content: start;
        padding: layout.$padding--small;
        padding-left: layout.$padding * 2 ;
        button {
             font-size: font.$size--small !important;
        }
       
    }
}