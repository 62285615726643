@import '~@strategies/tokens/layout';
@import '~@strategies/tokens/color';
@import '~@strategies/tokens/font';

$blue--light: #EDEBFF;
$blue--dark: #272b2c;

.MinutesPanel {
    // height: 100%;
    // width: 70%;
    flex: 1 1;
    align-items: center;
    // height: 100%;
    margin: layout.$padding--smallest;
    border-radius: layout.$border-radius--small;
    padding: 0px 0px 5px 0px;

    .rc-slider-track {
        background-color: #6666FF
    }
    .rc-slider-handle {

        border:solid 2px #050038;
        background-color: #050038;
        opacity: 1;
    }

    .rc-slider-rail {
        background-color: #050038;
        opacity: 0.4;
    }
    .infoTextWrapperOff {
        color: lightgray;
        font-size: 14px;

        b {
            font-size: 20px;
        }
    }
    .infoTextWrapper {
        font-size: 14px;

        b {
            font-size: 20px;
        }
    }

    .minutesWrapper {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px 5px 0px;
        align-content: center;
        flex-wrap: nowrap;
        border-radius: 5px;
        // padding-left: $padding--smallest;
        // padding-right: $padding--smallest;
        flex: 1 1;
        align-items: center;
        margin: 10px;

        .minutesWrapper {
            flex: 0.86 1;
            display: flex;
            width: 100%;
            background-color: $blue--light;

            padding: 5px;
            padding-top: 6px;

            border-radius: 10px;

            .minutes_holder {
                left: 0;
                margin-right: 30px;

                .rc-slider .rc-slider-horizontal {
                    margin-right: 30px;
                }
            }

            .rc-slider {
                right: 0;

            }

            .rc-slider-handle{

                border:solid 2px #272b2c;
            }
            .rc-slider-handle .rc-slider-handle-dragging {
                background-color: black;
                border-color: white;
                border: #050038;
            }

            .rc-slider-track {
                background-color: white;
            }
        }

        .MinuteTextWrapper {
            display: flex;
            flex-wrap: nowrap;

            .minute_text {
                width: auto;
                height: auto;
                white-space: pre;
                overflow: visible;
                font-weight: 500;
                font-family: "Inter-Medium", "Inter", sans-serif;
                color: #050038;
                letter-spacing: 0px;
                line-height: 1.2;
                text-align: left;
                padding-right: 15px;
            }
        }
    }

    .dayWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        flex-wrap: nowrap;
        border-radius: 5px;
        border: 1px solid #F2F5FA;
        padding-left: $padding--smallest;
        padding-right: $padding--smallest;
        align-items: center;

        .days-selector {
            padding: 5px;
            margin: 0 0 0 0.5rem;
            background-color: var(--token-f440fbaa-40cc-4295-a0cc-f46234c37aa4, #edebff);
            border-radius: 10px;
            flex: 0.8 1;

            .ReactForm__RadioSlider {
                height: 80%;

                .ReactForm__RadioSlider-input {
                    display: block;
                    border-color: #EDEBFF;
                    vertical-align: middle;
                    overflow: visible;
                    height: 80%;
                    background: #EDEBFF;
                }
            }

            .ReactForm__RadioSlider-option {
                padding: 3px 0;
                font-size: .657rem;
            }

        }

        .dayTextWrapper {
            .day_text {}
        }
    }


}