@import '~@strategies/tokens/layout';

.LegendWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    right: 0;
    min-width: fit-content;
    height: 100%;
    padding: $padding ;



    &:last-child {
     left: 0;   
     right: auto;
     top: 60%;
     width: 20%;
     height: auto;
    }
    
}
